var NRD = window.NRD || {};
var $ = NRD.jquery;

var gbCartChanged = false;
function RefreshPageIfCartChanged() {
    sessionStorage.setItem('MissingModalClosed', 'true');
    if (true === gbCartChanged) {
        RefreshThisPage();
    }
}

function RefreshPage() {
    RefreshThisPage();
}

function recordAnalyticsForAdd(pId, firstItem, price, eVar62, addlProperties) {
    //var s = s_gi(s_account);
    s.linkTrackVars = 'products,events,eVar48,eVar18';
    var foundeVar18 = false;
    if (s.eVar18 !== undefined) {
        foundeVar18 = true;
    }
    if (firstItem) {
        if (foundeVar18) {
            s.linkTrackEvents = 'scAdd,scOpen,event23,event25';
            s.events = 'scAdd,scOpen,event23,event25';
        }
        else {
            s.linkTrackEvents = 'scAdd,scOpen,event23';
            s.events = 'scAdd,scOpen,event23';
        }

    } else {
        if (foundeVar18) {
            s.linkTrackEvents = 'scAdd,event23,event25';
            s.events = 'scAdd,event23,event25';
        }
        else {
            s.linkTrackEvents = 'scAdd,event23';
            s.events = 'scAdd,event23';
        }

    }

    s.eVar48 = 'original order';
    s.products = ';' + pId + ';;;event23=' + price + ';eVar62=' + eVar62 + "|" + addlProperties;
    s.tl(this, 'o', 'Add to Cart');
}

//function s_trackTryWith(szlocation, tlTitle) {
//    s.linkTrackVars = 'events,eVar18';
//    s.eVar18 = szlocation;
//    s.linkTrackEvents = 'event25';
//    s.events = 'event25';
//    s.tl(true, 'o', tlTitle);
//}

function recordAnalyticsFreeGiftSelected(pId, price) {
    s.products = ';' + pId + ';1;' + price + ';;';
    s.events = 'scAdd';
    s.tl(this, 'o', 'select free gift');
}

function recordReflektionForAdd(productID) {
    var url = window.location.pathname;
    var szLocation = '';
    switch (url) {
        case '/checkout/cart':
            szLocation = 'cart';
            break;
        case '/products/category':
            szLocation = 'category';
            break;
        case '/':
            szLocation = 'home';
            break;
        case '/landing/':
            szLocation = 'landing';
            break;
        case '/products/search':
            szLocation = 'search';
            break;
        default:
            if (url.includes("/product/", 0)) {
                szLocation = 'pdp';
            }
            else if (url.includes("/collection/", 0)) {
                szLocation = 'collection';
            }
            else {
                szLocation = 'other';
            }
            break;
    }
    rfk.push(['trackEvent', {
        type: 'a2c',
        name: szLocation,
        value: {
            products: [{
                sku: productID,
            }],
        },
    }]);

    if (szLocation == 'category') {
        RFKClickProductEvent(productID, "rfkid_10");
    }
    else if (szLocation == 'search') {
        RFKClickProductEvent(productID, "rfkid_7");
    }
    else if (szLocation == 'collection') {
        RFKClickProductEvent(productID, "rfkid_12");
    }
}

function RwdOnLoad() {
    // Make call for LivePerson to be run (check that function exists in case we disable)
    if (typeof sseLivePersonSetup === 'function') {
        sseLivePersonSetup();

        if (typeof sseLivePersonLoggedIn === 'function') {
            sseLivePersonLoggedIn();
        }

        if (typeof sseLivePersonCheckout === 'function') {
            sseLivePersonCheckout();
        }
    }

    // Look for beacon tags that are deferred to execute after onload event, copy to the real "src" attribute to make
    // the requests
    $('img[data-beacon-src],script[data-beacon-src],iframe[data-beacon-src]').each(function (index) {
        $(this).attr('src', $(this).attr('data-beacon-src'));
    });

    // Look for deferred script blocks
    $('script[type=\'text/plain\']').each(function (index) {
        // Set type to JavaScript and append/remove to get it to fire
        this.setAttribute('type', 'text/javascript');
        var oParentNode = this.parentNode;
        oParentNode.appendChild(this.cloneNode(true));
        oParentNode.removeChild(this);
    });

    // Look for resources that are deferred
    $('link[data-defer-href]').each(function (index) {
        $(this).attr('href', $(this).attr('data-defer-href'));
    });
}

function setQuantity(index) {
    var quantityArray = $('select[id*=qty]');
    if (quantityArray != null && quantityArray[index] != null && quantityArray[index].value == '') {
        $(quantityArray[index]).val('1');
    }
    var itemArray = $('input[id*=itemNumber]');
    if (itemArray && itemArray[index] != null && itemArray[index].value == '') {
        $(quantityArray[index]).val('');
    }
}

var bAddToCartInProgress = false;
function addItemToCart(buttonObject, listOfProductIds, atcbInstanceId, couponCode, reloadPage, bMissingModal, recipeId, addlOmintureProperties, iQty) {
    // Need to avoid multiple calls due to double clicks, used to hide the button but can't any more with animated progress image
    if (bAddToCartInProgress) {
        return;     // Already a call, so leave
    }
    bAddToCartInProgress = true;

    var arrIds = listOfProductIds.split(' ');

    // Convert the JavaScript object into a jQuery object (do once for performance)
    var jqButtonObject = $(buttonObject);

    var szUniqueId = arrIds[0];
    if (recipeId !== undefined) {
        szUniqueId = recipeId;
    }

    // Find the quantity with the product id and instance id value
    var pQuantity = 1;
    if (iQty !== undefined) {
        pQuantity = iQty;
    }
    var oQtySelect = $('#atcb_qty_' + szUniqueId + '_' + atcbInstanceId);
    if (oQtySelect.length > 0) {
        pQuantity = oQtySelect.val();
    }
    if (isNaN(pQuantity)) {         // Make sure that current quantity is a number, if not set to 1
        pQuantity = 1;
    }

    // Check if a zip code is already set
    szZipSet = $('#IS_ZIP_CODE_SET').val();

    // Ajax CSRF token might not be present if disabled
    var AjaxTokenForUrl = '';
    if (typeof Ajax_CSRF_Token !== 'undefined') {
        AjaxTokenForUrl = '&ajaxToken=' + Ajax_CSRF_Token;
    }

    for (i = 0; i < arrIds.length; i++) {
        var pId = arrIds[i];
        var addPath = '/addtocart.ashx?productId=' + pId + '&quantity=' + pQuantity + AjaxTokenForUrl;

        if (typeof couponCode !== 'undefined') {
            var arrCouponIds = couponCode.split(',');
            var couponcodestoapply = '';
            for (j = 0; j < arrCouponIds.length; j++) {
                var oUseCoupon = $('#usecoupon_' + szUniqueId + '_' + atcbInstanceId + '_' + arrCouponIds[j]);
                if ((oUseCoupon.length > 0) && (oUseCoupon[0].checked)) {
                    if (couponCode) {
                        couponcodestoapply = couponcodestoapply + arrCouponIds[j] + ',';
                    }
                }
            }
            if (couponcodestoapply.length > 0) {
                couponcodestoapply = couponcodestoapply.substring(0, couponcodestoapply.length - 1);
                addPath = addPath + '&couponcode=' + couponcodestoapply;
            }
        }

        $.ajax({
            type: 'GET',
            url: addPath,
            cache: false,
            dataType: 'html',
            complete: function (data, status) {
                // This fires after success
                bAddToCartInProgress = false;       // Set to show done with the call

                var retData = eval('(' + trimString(data.responseText) + ')');
                if (retData.itemAdded != null) {
                    $('#atcb_div_' + atcbInstanceId).addClass('addToCart_isOpen');
                    jqButtonObject.attr('disabled', 'disabled');
                    let iCurrentQty = $('#atcb_qtys_' + atcbInstanceId).text();
                    if (iCurrentQty == "") {
                        iCurrentQty = 0;
                        HandleAddToCartButton(jqButtonObject, true, pQuantity, false);
                    }

                    let iNextQty = parseInt(iCurrentQty) + parseInt(pQuantity);
                    if (parseInt(iNextQty) == 10) {
                        $('#atcb_qtys_text_' + atcbInstanceId).text(' Max');
                    }
                    else {
                        $('#atcb_qtys_text_' + atcbInstanceId).text(' in Cart');
                    }

                    // if no qty left in cart then set classes to button shows add to cart instead of plus and minus buttons.
                    if (parseInt(iNextQty) == 0) {
                        $('#atcb_btn_' + atcbInstanceId).removeAttr('disabled', 'disabled');
                        if ('True' == reloadPage) {
                            $('#atcb_div_' + atcbInstanceId).addClass('isHidden');
                        }
                        else {
                            $('#atcb_div_' + atcbInstanceId).removeClass('addToCart_isOpen');
                        }
                    }
                    else { 
                        // If qty is less than 10 both minus and plus button should be enabled.
                        if (parseInt(iNextQty) < 10) {
                            $('#atcb_btn_decrease_' + atcbInstanceId).removeAttr('disabled', 'disabled');
                            $('#atcb_btn_increase_' + atcbInstanceId).removeClass('isDisabled');
                            $('#atcb_btn_increase_' + atcbInstanceId).removeAttr('disabled', 'disabled');
                            $('#atcb_btn_increase_' + atcbInstanceId).focus();
                        }
                    }
                    // If qty is 10 minus button should be disabled.
                    if (parseInt(iNextQty) >= 10) {
                        $('#atcb_btn_increase_' + atcbInstanceId).attr('disabled', 'disabled');
                        $('#atcb_btn_increase_' + atcbInstanceId).addClass('isDisabled');
                    }

                    $('#atcb_qtys_' + atcbInstanceId).text(iNextQty);
                    $('#pb_alert_' + atcbInstanceId).addClass("isHidden");
                }
                else {
                    $('#pb_alert_' + atcbInstanceId).removeClass("isHidden");
                }
                if (retData.isAuthenticated) {
                    for (j = 0; j < arrCouponIds.length; j++) {
                        var oCoupon = $('#usecoupon_' + szUniqueId + '_' + atcbInstanceId + '_' + arrCouponIds[j])[0];
                        if (null == oCoupon) {
                            oCoupon = $('#usecoupon_' + szUniqueId + '__' + arrCouponIds[j])[0];
                        }
                        if (null != oCoupon && oCoupon.checked) {
                            clickApplyCoupon(oCoupon, szUniqueId, atcbInstanceId, arrCouponIds[j], oCoupon.dataset.promoEndDate, oCoupon.dataset.promoMessage, oCoupon.dataset.promotionId, 'true', '', bMissingModal);
                        }
                    }
                }

                app.renderProgress();
            },
            success: function (data) {
                retData = eval('(' + trimString(data) + ')');
                if (retData.itemAdded != null) {
                    UpdateCartContents(retData.numberItems, retData.htmlHeaderCart);

                    if (Modernizr.sessionstorage) {
                        sessionStorage.setItem('cartQty', retData.numberItems);
                        sessionStorage.setItem('cartContents', retData.htmlHeaderCart);
                    }

                    recordAnalyticsForAdd(retData.itemAdded, retData.firstItem, retData.price, retData.eVar62, addlOmintureProperties);

                    recordReflektionForAdd(retData.itemAdded);

                    recordPinterestForAdd(retData.itemAdded, retData.price, pQuantity);

                    agilOneClientCartUpdated(retData.cartProducts);

                    FireImgPixel('data-ttd2');

                    CallLiveIntentPush({'event':'addToCart'});

                    gbCartChanged = true;       

                    if ('True' == reloadPage) {
                        if (retData.isAuthenticated) {
                            for (j = 0; j < arrCouponIds.length; j++) {
                                if (arrCouponIds[j].length > 0) {
                                    var oCoupon = $('#usecoupon_' + szUniqueId + '__' + arrCouponIds[j])[0];
                                    if (null != oCoupon && oCoupon.checked) {
                                        clickApplyCoupon(oCoupon, szUniqueId, atcbInstanceId, arrCouponIds[j], oCoupon.dataset.promoEndDate, oCoupon.dataset.promoMessage, oCoupon.dataset.promotionId, 'true', '', bMissingModal);
                                    }
                                }
                            }
                        }
                        window.location.href = window.location.href;
                    }
                }
            },
            error: function () {
                $('#pb_alert_' + atcbInstanceId).removeClass("isHidden");
            },
        });
    }

}

function addMultipeItemsToCart(buttonObject, listOfProductIds, atcbInstanceId, buttontext) {
    // Need to avoid multiple calls due to double clicks, used to hide the button but can't any more with animated progress image
    if (bAddToCartInProgress) {
        return;     // Already a call, so leave
    }
    bAddToCartInProgress = true;

    var arrIds = listOfProductIds.split(',');

    // Convert the JavaScript object into a jQuery object (do once for performance)
    var jqButtonObject = $(buttonObject);

    // Find the quantity with the product id and instance id value
    var pQuantity = 1;

    jqButtonObject.attr('disabled', 'disabled');
    jqButtonObject.addClass('isLoading');
    jqButtonObject.addClass('isAdded');
    jqButtonObject.addClass('isDisabled');

    // Ajax CSRF token might not be present if disabled
    var AjaxTokenForUrl = '';
    if (typeof Ajax_CSRF_Token !== 'undefined') {
        AjaxTokenForUrl = '&ajaxToken=' + Ajax_CSRF_Token;
    }

    var szTooltipDest = 'cart';
    var addPath = '/handlers/addmultipleproductstocart.ashx?products=' + listOfProductIds + AjaxTokenForUrl;
    var firstitemincart;
    var prices = '';
    $.ajax({
        type: 'GET',
        url: addPath,
        cache: false,
        dataType: 'html',
        complete: function (data, status) {
            // This fires after success
            bAddToCartInProgress = false;       // Set to show done with the call

            var retData = eval('(' + trimString(data.responseText) + ')');
            var tooltipId = 'atcb_tt_' + atcbInstanceId;
            var message = '';

            if (retData.itemAdded != null) {

                HandleAddToCartAutoReorderButtons(jqButtonObject, true, pQuantity, true);

                setTimeout(function () {
                    resetCartButton(jqButtonObject, pQuantity, atcbInstanceId, true, buttontext);
                }, 8000);

            } else {
                jqButtonObject.text('Could Not Add Items to Cart');
                setTimeout(function () {
                    resetCartButton(jqButtonObject, pQuantity, atcbInstanceId, true, buttontext);
                }, 8000);
            }
            app.renderProgress();
        },
        success: function (data) {
            jqButtonObject.removeClass('isLoading');

            retData = eval('(' + trimString(data) + ')');
            if (retData.itemAdded != null) {
                UpdateCartContents(retData.numberItems, retData.htmlHeaderCart);

                if (Modernizr.sessionstorage) {
                    sessionStorage.setItem('cartQty', retData.numberItems);
                    sessionStorage.setItem('cartContents', retData.htmlHeaderCart);
                }
                firstitemincart = retData.firstitemincart;

                var arrProductPriceQty = retData.itemAdded.split(',');

                for (i = 0; i < arrProductPriceQty.length; i++) {
                    var arrProductQty = arrProductPriceQty[i];
                    var arrSplit = arrProductQty.split(':');
                    var pId = arrSplit[0];
                    var price = arrSplit[1];
                    var iQty = arrSplit[2];

                    if (firstitemincart === pId) {
                        recordAnalyticsForAdd(pId, true, price);
                    }
                    else {
                        recordAnalyticsForAdd(pId, false, price);
                    }

                    recordReflektionForAdd(pId);

                    FireImgPixel('data-ttd2');

                    recordPinterestForAdd(pId, price, iQty);
                }

                gbCartChanged = true;

                agilOneClientCartUpdated(retData.cartProducts);

            }
        },
        error: function () {
            jqButtonObject.removeClass('isLoading');
            jqButtonObject.removeClass('isDisabled');
            jqButtonObject.removeClass('isAdded');
            jqButtonObject.removeAttr('disabled', 'disabled');
        },
    });
}

function clickApplyCoupon(checkboxObject, productId, atcbInstanceId, couponCode, promoEndDate, promoMessage, promotionId, recurse, ajaxHandlerId, bMissingModal) {
    var url = window.location.pathname;
    var origin = 0;
    var bNewProductUrl = false;
    var bMissingModalInProcess = false;

    var missingModalClosed = sessionStorage.getItem('MissingModalClosed');
    if ((undefined !== missingModalClosed) && (null !== missingModalClosed) && ('true' == missingModalClosed)) {
        bMissingModalInProcess = false;
    } else {
        if ('true' == bMissingModal) {
            bMissingModalInProcess = true;
        }
    }

    switch (url) {
        case '/products/deals':
            origin = 1;
            break;
        case '/products/product':
            //Need in case we revert to old URL syntax
            origin = 2;
            break;
        case '/products/deals-details':
            origin = 3;
            break;
        case '/checkout/cart':
            origin = 4;
            break;
        case '/products/category':
            origin = 5;
            break;
        case '/landing/':
            origin = 6;
            break;
        case '/':
            origin = 7;
            break;
        case '/products/search':
            origin = 8;
            break;
        default:
            if (url.includes("/product/", 0)) {
                bNewProductUrl = true;
                origin = 2;
            }
            break;
    }

    var queryString = '';
    if ([2, 3, 5, 6, 8].includes(origin)) {
        var search = window.location.search;
        if (search.includes('&productId=')) {
            search = search.substring(0, search.indexOf('&productId='));
        }
        if ((bNewProductUrl) && ('' !== ajaxHandlerId)) {
            if ('' !== search) {
                search = search + "&id=" + ajaxHandlerId;
            }
            else {
                search = "?id=" + ajaxHandlerId;
            }
        }
        queryString = search;
    }

    var coupons = $('.' + couponCode);
    var couponData = {
        couponInstances: [],
        productId: productId,
        couponCode: couponCode,
        atciId: atcbInstanceId,
        promoEndDate: promoEndDate,
        promoMessage: promoMessage,
        promotionId: promotionId,
        origin: origin,
        originURL: window.location.href
    };
    var checked = checkboxObject.checked;
    coupons.each(function (i, obj) {
        var input = $(this)[0];
        couponData.couponInstances.push({
            isChecked: checked,
            atciId: input.dataset.atciId,
            productId: input.dataset.productId,
        });
    });
    updateCoupon(couponData, checked, promoMessage, queryString, bMissingModal, bMissingModalInProcess);
}

function updateCoupon(coupons, checked, promoMessage, queryString, bMissingModal, bMissingModalInProcess) {
    var couponPath = '/handlers/couponhandler.ashx' + queryString;
    var couponJSON = JSON.stringify({ coupons: coupons });
    var productId = coupons.productId;
    var couponCode = coupons.couponCode;
    var atcbInstanceId = coupons.atciId;

    $.ajax({
        async: true,
        type: 'POST',
        url: couponPath,
        cache: false,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: couponJSON,
        success: function (data) {
            var response = data;
            if (response.IsAuthenticated) {
                response.CouponHtml.forEach(function (coupon, index) {
                    var coupondiv = '#coupon_div_' + coupon.productId + '_' + coupon.atciId + '_' + couponCode;
                    $(coupondiv).replaceWith(coupon.Html);
                    coupondiv = '#coupon_div_' + coupon.productId + '__' + couponCode;
                    if (null != coupondiv) {
                        $(coupondiv).replaceWith(coupon.Html);
                    }
                });

                app.destroyTooltips();
                app.createTooltips();

                if (false == bMissingModalInProcess) {
                    if (window.location.pathname == '/checkout/cart') {
                        window.location.href = window.location.href;
                    }
                }

                var s = s_gi(s_account);
                s.linkTrackVars = 'events,eVar21,products';
                if (checked) {
                    s.linkTrackEvents = 'event102';
                    s.events = 'event102';
                    s.products = ';' + productId + ';;;;';
                    s.eVar21 = promoMessage;
                } else {
                    s.linkTrackEvents = 'event103';
                    s.events = 'event103';
                    s.products = ';' + productId + ';;;;';
                    s.eVar21 = promoMessage;
                }
                s.tl(this, 'o', 'Clip Coupon');

                if (typeof response.ProductCouponCodes !== 'undefined' && null != response.ProductCouponCodes) {
                    var arrCouponIds = response.ProductCouponCodes.split(',');

                    for (j = 0; j < arrCouponIds.length; j++) {
                        var oCoupon = $('#usecoupon_' + productId + '_' + atcbInstanceId + '_' + arrCouponIds[j])[0];
                        clickApplyCoupon(oCoupon, productId, atcbInstanceId, arrCouponIds[j], oCoupon.dataset.promoEndDate, oCoupon.dataset.promoMessage, oCoupon.dataset.promotionId, 'true', '', bMissingModal);
                    }
                }

                if (Modernizr.sessionstorage) {
                    sessionStorage.setItem('cartContents', response.HeaderCartContents);
                    ClientCartUpdate();
                }
            } else {
                window.location.href = '/account/';
            }
            app.renderProgress();
        },
        error: function () {
            var couponerr = '#coupon_err_' + productId + '_' + atcbInstanceId + '_' + couponCode;
            $(couponerr).toggleClass('isHidden');
            var couponCheckbox = '#usecoupon_' + productId + '_' + atcbInstanceId + '_' + couponCode;
            $(couponCheckbox).prop('checked', false);
        },
    });
}

function clickFavorite(productId, productName) {
    var favoritePath = '/handlers/favoritehandler.ashx';
    var favoriteDivCurrent = $('#favorite_div_' + productId);
    var wasFavorite = favoriteDivCurrent.hasClass('favorite_isSelected');
    var favoriteJSON = JSON.stringify({ productId: productId, wasFavorite: wasFavorite, originURL: window.location.href, productName: productName });
    $.ajax({
        async: true,
        type: 'POST',
        url: favoritePath,
        cache: false,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: favoriteJSON,
        success: function (data) {
            var response = data;
            if (response.IsAuthenticated) {
                var favoriteDiv = $('#favorite_div_' + response.FavoriteHtml.productId);
                var favoriteBtn = $('#favorite_btn_' + response.FavoriteHtml.productId);
                var favoriteBtnSpan = $('#favorite_btn_span_' + response.FavoriteHtml.productId);
                var favoriteLabelSpan = $('#favorite_label_span_' + response.FavoriteHtml.productId);
                var favoriteStatusSpan = $('#favorite_status_span_' + response.FavoriteHtml.productId);
                if (wasFavorite) {
                    var addText = 'Add ' + response.FavoriteHtml.productName + ' to your favorites';
                    favoriteDiv.removeClass('favorite_isSelected');
                    favoriteBtn.attr('aria-label', addText);
                    favoriteLabelSpan.text('Add to ');
                    favoriteStatusSpan.text('Add ' + response.FavoriteHtml.productName + ' to your favorites');
                } else {
                    var removeText = 'Added ' + response.FavoriteHtml.productName + ' to your favorites';
                    favoriteDiv.addClass("favorite_isSelected");
                    favoriteBtn.attr('aria-label', removeText);
                    favoriteLabelSpan.text('Added to ');
                    favoriteStatusSpan.text('Added' + response.FavoriteHtml.productName + ' to your favorites');
                }
            } else {
                window.location.href = '/account/'
            }
        }
    });
}

function hideEktronBanner() {
    $(".dismissiveBanner").addClass("dismissiveBanner_isHidden");
    $(".filter_parent").children(".filter-hd").attr('tabindex', '-1').focus();
    var removeBannerPath = "/handlers/removeektronbannerhandler.ashx";
    $.ajax({
        async: true,
        type: "POST",
        url: removeBannerPath,
        cache: false,
    });
};

function UpdateCartContents(cartCount, cartContent) {
    var headerCartTotalQtyCount = $('#headerCartTotalQtyCount');
    var miniCartTotalQtyCount = $('#miniCartTotalQtyCount');
    //hide this first - edge cases in mobile where it still displays
    miniCartTotalQtyCount.addClass('isHidden');
    if (0 !== cartCount) {
        if (headerCartTotalQtyCount != null) {
            headerCartTotalQtyCount.html(cartCount);
            miniCartTotalQtyCount.removeClass('isHidden');
        }
    } else {
        miniCartTotalQtyCount.addClass('isHidden');
    }

    var headerCartContainer = $('#headerCartContainer');
    if (headerCartContainer != null) {
        headerCartContainer.html(cartContent);
    }
}

function SaveCartContents() {
    if (Modernizr.sessionstorage) {
        var cartQty = $('#headerCartTotalQtyCount').html();
        var cartContents = $('#headerCartContainer').html();

        sessionStorage.setItem('cartQty', cartQty);
        sessionStorage.setItem('cartContents', cartContents);
    }
}

function resetCartButton(oCartButton, pQuantity, atcbInstanceId, bMultipeProduct, buttontext) {
    HandleAddToCartAutoReorderButtons(oCartButton, false, pQuantity, bMultipeProduct, buttontext);
    oCartButton.removeAttr('disabled', 'disabled');
    oCartButton.removeClass('isAdded');
    oCartButton.removeClass('isDisabled');
    if (oCartButton.hasClass('btn_reorder')) {
        callShowAlreadyOnAutoReorder(atcbInstanceId);
    }
}

function resetServerSession() {
    var resetTimer = new Event('resetInactivityTimer');
    $.ajax({
        async: true,
        type: 'POST',
        url: '/handlers/resetsessionhandler.ashx',
        cache: false,
        success: function (data) {
            window.dispatchEvent(resetTimer);
        },
    });
}

function getAutoReorderFrequency(iOPrd, szInstanceId) {
    var iFrequency = 0;
    var szFreqRadio = 'atcb_freq_' + iOPrd + '_' + szInstanceId;
    var oFreqRadioChecked = $('input[name=' + szFreqRadio + ']:checked');
    if (oFreqRadioChecked.length > 0) {
        iFrequency = parseInt(oFreqRadioChecked.val());
    }

    return iFrequency;
}

function HandleAddToCartButton(oButton, bAdded, iQty, bMultipeProduct, buttontext) {
    var oAddToCartButton;

    oAddToCartButton = $(oButton);

    var szQty = '';
    if (iQty > 1) {
        szQty = iQty;
    }

    var szButtonTextPrefix;
    var szButtonText;
    if (bAdded) {
        if (bMultipeProduct) {
            szButtonText = 'Items Added to Cart';
        }
    }
    else {
        if (bMultipeProduct) {
            if (undefined !== buttontext) {
                szButtonText = buttontext;
            }
            else {
                szButtonText = 'Buy These Items Again';
            }
        }
    }
    if (bMultipeProduct) {
        oAddToCartButton.text(szButtonText);
    }
    else {
        oAddToCartButton.text(iQty + ' in Cart');
        
    }
}

function HandleAddToCartAutoReorderButtons(oButton, bAdded, iQty, bMultipeProduct, buttontext) {
    var oAddToCartButton;
    var oAddToReorderButton;

    // Highly dependent on markup for button siblings
    if (oButton.hasClass('btn_primary')) {
        oAddToCartButton = $(oButton);
        oAddToReorderButton = oAddToCartButton.next('button');
    } else {
        oAddToReorderButton = $(oButton);
        oAddToCartButton = oAddToReorderButton.prev('button');
    }

    var szQty = '';
    if (iQty > 1) {
        szQty = iQty;
    }

    var szButtonTextPrefix;
    var szButtonText;
    //var szCurrentButtonText = oAddToCartButton.text();
    if (bAdded) {
        if (bMultipeProduct) {
            szButtonText = 'Items Added to Cart';
        }
        else {
            szButtonTextPrefix = szQty + ' Added to ';
        }
    }
    else {
        if (bMultipeProduct) {
            if (undefined !== buttontext) {
                szButtonText = buttontext;
            }
            else {
                szButtonText = 'Buy These Items Again';
            }
        }
        else {
            szButtonTextPrefix = 'Add ' + szQty + ' to ';
        }
    }
    if (bMultipeProduct) {
        oAddToCartButton.text(szButtonText);
    }
    else {
        oAddToCartButton.text(szButtonTextPrefix + 'Cart');
    }

    var oSpanChildren = oAddToReorderButton.contents('span');
    if (oSpanChildren.length > 1) {
        $(oSpanChildren.get(0)).text(szButtonTextPrefix + 'Reorder');
    }
}

function callShowAlreadyOnAutoReorder(atcbInstanceId) {
    showAlreadyOnAutoReorder(atcbInstanceId);
}

function trimString(str) {
    return str.replace(/^\s*(\S*(\s+\S+)*)\s*$/, '$1');
};

function LoginUser(szBaseURL, szUID, szTimestamp, szSignature) {
    var szURLPath = szBaseURL + 'secure/user/loginsocial.ashx';
    var myData = 'UID=' + szUID + '&timestamp=' + szTimestamp + '&sig=' + encodeURIComponent(szSignature);
    var returnMessage = '';
    $.ajax({
        type: 'POST',
        url: szURLPath,
        cache: false,
        async: false, // Another caller is waiting for this function so I need to do async as false.
        timeout: 25000,
        data: myData,
        dataType: 'text',
        success: function (data) {
            returnMessage = data;
        },
        error: function (data, textStatus, textError) {
            returnMessage = data;
        },
    });
    return returnMessage;
}

function FindUser(szBaseURL, szUID, szTimestamp, szSignature, bMyAccount, szFromPath) {
    var szURLPath = szBaseURL + 'secure/user/loginsocialfind.ashx';
    var myData = 'UID=' + szUID + '&timestamp=' + szTimestamp + '&sig=' + encodeURIComponent(szSignature) + '&from=' + szFromPath;
    if (bMyAccount == true) {
        myData = myData + '&myacct=true';
    }
    var returnMessage = 0;
    $.ajax({
        type: 'POST',
        url: szURLPath,
        cache: false,
        async: false,
        timeout: 25000,
        data: myData,
        dataType: 'text',
        success: function (data) {
            returnMessage = data;
        },
        error: function (data, textStatus, textError) {
            returnMessage = data;
        },
    });
    return returnMessage;
}

function ShowHideYouMayAlsoLike() {
    if (FiltersApplied()) {
        $('#YMAL').addClass('isHidden');
    } else {
        $('#YMAL').removeClass('isHidden');
    }
}

function FiltersApplied() {
    if ($('input[data-shs-filter]:checked').length > 0) {
        return true;
    }

    return false;
}

function ManageFiltersAndSort(bClearAll, bForceCall, focusSort) {
    if (!bForceCall) {
        if ((IsBreakpoint_SM()) || (IsBreakpoint_MD())) {
            return;
        }
    }

    if (typeof document.activeElement !== 'undefined') {
        var checkBoxWithFocusFilterId = $(document.activeElement).data('shs-filter');
    }

    var filterCount = $('input[data-shs-filter]:checked').length;
    var newURL = GetPageFilterSortURL(bClearAll);

    if (FiltersApplied()) {
        $('div[id^=\'ProductRecommendationsCarousel\']').hide();
    } else {
        $('div[id^=\'ProductRecommendationsCarousel\']').show();
        // Need this to initialize carousel
        app.destroyCarousels();
        app.createCarousels();
        app.destroyTooltips();
        app.createTooltips();
    }

    var newBrowserURL = newURL;

    if (newURL.indexOf('?') !== -1) {
        newURL += '&mfAjax=true';
    } else {
        newURL += '?mfAjax=true';
    }

    $.ajax({
        type: 'GET',
        url: newURL,
        cache: false,
        dataType: 'html',
        success: function (data) {
            $('#AjaxFilterRefreshArea').html($(data).find('#AjaxFilterRefreshArea')
                .html());
            $('#AjaxFilterRefreshArea2').html($(data).find('#AjaxFilterRefreshArea2')
                .html());
            ShowHideYouMayAlsoLike();
            AdjustProductBlockAfterAjax('');
            var $sortSelect = $('#AjaxFilterRefreshArea').find('.customSelect').children('select');
            var $filterCountContainer = $('#AjaxFilterRefreshArea').find('.js-filterPanelTrigger-count');
            var $filterCountLabel = $('#AjaxFilterRefreshArea').find('.js-filterPanelTrigger-label');

            if (filterCount > 0) {
                $filterCountContainer.removeClass('isVisuallyHidden').text('(' + filterCount + ')');
                $filterCountLabel.removeClass('isHidden').addClass('isVisuallyHidden');
            } else {
                $filterCountContainer.addClass('isHidden').text('(zero)');
                $filterCountLabel.addClass('isHidden').removeClass('isVisuallyHidden');
            }

            app.destroyFilterPanel();
            app.renderFilterPanel();
            app.renderFilterToggle();

            ExecuteAjaxScripts(data);

            app.destroyTooltips();
            app.createTooltips();

            if (!FiltersApplied() && bClearAll) {
                $sortSelect.focus();
            } else if (focusSort) {
                setTimeout(function () {
                    $sortSelect.focus();
                }, 0);
            } else if (bForceCall && $('.filterTags').children('.filterTag').length > 0) {
                $('.filterTags').children('.filterTag').focus();
            } else if (bForceCall && !$('.filterTags').children('.filterTag').length > 0) {
                $sortSelect.focus();
            } else if (!FiltersApplied()) {
                $('.filter_parent .isCurrent').focus();
            } else if (typeof checkBoxWithFocusFilterId !== 'undefined') {
                $("input[data-shs-filter='" + checkBoxWithFocusFilterId + "']").focus();
            }

            history.replaceState(null, 'Filters', newBrowserURL);   // Change browser URL to reflect filters
        },
    });

    ScrollToElementId('div', 'AjaxFilterRefreshArea');
}

function GetPageFilterSortURL(bClearAll) {
    var uri = new URI(location);

    var filterAndSortQuerystringParameters = '';

    filterAndSortQuerystringParameters += ManageFiltersGetQSParam('fltr', bClearAll);
    uri.removeQuery('fltr');       // Take off querystring if originally present on the URL so not duplicated

    filterAndSortQuerystringParameters += ManageSortGetQSParam();
    uri.removeQuery('sb');                  // Take off querystring if originally present on the URL so not duplicated

    var newURL = uri.path() + '?' + uri.query();

    if (newURL.indexOf('?') !== -1) {
        newURL += filterAndSortQuerystringParameters;
    } else {
        newURL += filterAndSortQuerystringParameters.replace('&', '?');
    }

    return newURL;
}

function ManageSortGetQSParam() {
    var oDropDownListSortBy = $('[id*=\'DropDownListSortBy\']');
    if (oDropDownListSortBy.length != 0) {
        var sortByValue = oDropDownListSortBy.val();
        sessionStorage.setItem('sortByValueSB', sortByValue);
        if ('0' !== sortByValue) {
            return '&sb=' + sortByValue;
        }
    } else {
        var prevSortByValueSb = sessionStorage.getItem('sortByValueSB');
        if (prevSortByValueSb !== null) {
            if ('0' !== prevSortByValueSb) {
                return '&sb=' + prevSortByValueSb;
            }
        }
    }

    return '';
}

function ManageFiltersGetQSParam(querystringName, bClearAll) {
    var attributeName = 'data-shs-filter';

    var checkedItems;
    $('input[' + attributeName + ']:checked').each(function (index) {
        if (bClearAll) {
            this.checked = false;
        } else {
            if (undefined === checkedItems) {
                checkedItems = this.getAttribute(attributeName);      // First one so set attribute value
            } else {
                checkedItems = checkedItems + '|' + this.getAttribute(attributeName);    // Already at least one so pipe delimit
            }
        }
    });

    if (undefined !== checkedItems) {
        return '&' + querystringName + '=' + checkedItems;
    }

    return '';
}

// Use CORS to POST email address to secure page on server
function postEmailSignupToServer(szEmail, szHost, szProp19Text) {
    $.ajax({
        type: 'POST',
        data: { TextBoxEmail: szEmail },
        url: 'https://' + szHost + '/secure/account/signupforemail.ashx',
        cache: false,
        timeout: 20000,
        error: function (XMLHttpRequest, textStatus, errorThrown) { // alert(textStatus + ", " + errorThrown);
        },
        success: function (data, textStatus, XMLHttpRequest) {
            if (undefined !== szProp19Text) {
                s_genericTrackingProp19(this, 'None', szProp19Text, 'Email Signup');
            }
        },
        async: false,      // Without this it returns right away to caller
    });
}

function updateBundleBuilder() {
    var selectedBundleItems = $('input[type=\'radio\'][data-radio-name=\'bundleProductBlock\']:checked');
    var prodIds = [];
    $(selectedBundleItems).each(function (radio) {
        prodIds.push($(this).data('product-id'));
    });

    $('#HiddenBundleProductIds').val(prodIds.join());
}


function IsNumberKey(e) {
    return (((e.keyCode || e.key) >= 48 && (e.keyCode || e.key) <= 57 && !e.shiftKey) || ((e.keyCode || e.key) >= 96 && (e.keyCode || e.key) <= 105));
}

function IsPhoneNumberEditKey(e) {
    return ((e.keyCode || e.key) === 8
        || (e.keyCode || e.key) === 46
        || (e.keyCode || e.key) === 37
        || (e.keyCode || e.key) === 39
        || (e.keyCode || e.key) === 9
        || (e.keyCode || e.key) === 16
        || (e.keyCode || e.key) === 36
        || (e.keyCode || e.key) === 35);
}

function IsBackSpace(e) {
    return ((e.keyCode || e.key) == 8);
}

// WARNING:  Only use this when need to open the dialog via JavaScript, has scrolling issue right now when you use it!!  Also only use with modalPopup.ascx!
function OpenModalBox(szModalId) {
    // Due to bug need to dynamically add the "js-modal" just before open popup from JS
    $('#' + szModalId + '-cont').addClass('js-modal');
    window.app.eventBus.trigger('toggleModalById', szModalId);
}

function HideShowWithCheckbox(oCheckBox, szIdToHideShow) {
    if (oCheckBox.checked) {
        $('#' + szIdToHideShow).show();
    } else {
        $('#' + szIdToHideShow).hide();
    }
}

function handleChangeAutoReorderFrequency(oFreqSelect, atcbInstanceId) {
    var iFrequency = parseInt(oFreqSelect.value);
    var oAddToCartButton = $('#atcb_btn_' + atcbInstanceId);
    var oAddToCartButtonArea = $('#atcb_btn_area_' + atcbInstanceId);
    // var oAlreadyOnAutoReorder = $("#atcb_arw_" +atcbInstanceId);    //May not be present...
    var oAlreadyOnAutoReorder = $('#atcb_arw_global_' + atcbInstanceId);    // May not be present...
    var oQtyFs = $('#atcb_qtyfs_' + atcbInstanceId);

    if (0 === iFrequency) {
        oAddToCartButton.text('Add to Cart');
        oAddToCartButton.removeClass('btn_secondary');
        oAddToCartButton.addClass('btn_primary');
        if (oAlreadyOnAutoReorder.length > 0) {
            oAlreadyOnAutoReorder.hide();
        }
        oQtyFs.show();
        oAddToCartButtonArea.show();
    } else {
        oAddToCartButton.text('Add to Reorder');
        oAddToCartButton.addClass('btn_secondary');
        oAddToCartButton.removeClass('btn_primary');
        if (oAlreadyOnAutoReorder.length > 0) {
            oAlreadyOnAutoReorder.show();
            oQtyFs.hide();
            oAddToCartButtonArea.hide();
        }
    }
}

function showAlreadyOnAutoReorder(atcbInstanceId) {
    // $(".atcb_arw_global").clone().attr("id", "atcb_arw_" + atcbInstanceId).removeClass("atcb_arw_global").insertAfter("#atcb_qtyfs_" + atcbInstanceId).show();
    $('#atcb_arw_global_' + atcbInstanceId).show();
    // $("#atcb_qtyfs_" +atcbInstanceId).hide();
    $('#atcb_btn_area_' + atcbInstanceId).hide();
    $('#atcb_setar_' + atcbInstanceId).addClass('isDisabled');
    $('#atcb_qtyds_' + atcbInstanceId).addClass('isDisabled');
}


// Method to find all auto reorder frequencies selects and determine instance ids to adjust them
function adjustAddToCartButtonForAutoReorderFrequencies() {
    $('select[id*=\'atcb_freq_\']').each(function () {
        var oSelect = $(this);
        var instanceId;
        var idPieces = oSelect.attr('id').split('_');
        if (4 === idPieces.length) {
            instanceId = idPieces[3];
            handleChangeAutoReorderFrequency($(this), instanceId);
        }
    });
}

// Log the date selected that has the given slotid (should only be called from pages using HTTPS)
function TimeWindowLogging(slotId, dateSelected) {
    $.ajax({
        type: 'GET',
        url: '/secure/account/timewindowlogging.ashx?slotId=' + slotId + "&date=" + dateSelected,
        cache: false,
        async: true,    // Don't care about answer
        timeout: 7500,
        success: function (data) {
            // Nothing to do
        },
    });
}

function PageVisitedAlready() {
    // console.log("antiCsrfToken: " +Ajax_CSRF_Token);
    if (Modernizr.sessionstorage) {
        var antiCsrfDictionaryStr = sessionStorage.getItem('antiCsrfDictionary');

        if ((undefined !== antiCsrfDictionaryStr) && (null !== antiCsrfDictionaryStr)) {
            var antiCsrfDictionary = $.parseJSON(antiCsrfDictionaryStr);

            for (key in antiCsrfDictionary) {
                if (antiCsrfDictionary.hasOwnProperty(key)) {
                    // console.log("antiCSRF key = " + key + ", value = " + antiCsrfDictionary[key]);
                }
            }

            if (undefined != antiCsrfDictionary[Ajax_CSRF_Token]) {
                // console.log("Previously visited page...  Back or forward button pressed");
                s_genericTrackingProp19(this, 'None', 'Navigate via Browser History', '');
                return true;
            }
        }
    }

    return false;
}

function SetPageVisited() {
    if (Modernizr.sessionstorage) {
        var antiCsrfDictionaryStr = sessionStorage.getItem('antiCsrfDictionary');

        var antiCsrfDictionary;
        if ((undefined !== antiCsrfDictionaryStr) && (null !== antiCsrfDictionaryStr)) {
            antiCsrfDictionary = $.parseJSON(antiCsrfDictionaryStr);
        } else {
            antiCsrfDictionary = {};
        }

        antiCsrfDictionary[Ajax_CSRF_Token] = (new Date).getTime();     // Don't really use value, just epoch time to see order
        sessionStorage.setItem('antiCsrfDictionary', JSON.stringify(antiCsrfDictionary));
    }
}

function ClientCartUpdate() {
    if (Modernizr.sessionstorage) {
        var iCartQty = Number(sessionStorage.getItem('cartQty'));
        var szCartContents = sessionStorage.getItem('cartContents');

        console.log('Update cart qty with [' + iCartQty + ']');
        UpdateCartContents(iCartQty, szCartContents);
    }
}

function ClearClientCartInfo() {
    if (Modernizr.sessionstorage) {
        sessionStorage.removeItem('cartQty');
        sessionStorage.removeItem('cartContents');
    }
}


/** ***************************************
**  Violator JS
*****************************************/
if ($('.violator').length == true) {
    var violator = $('.violator');
    var body = $('body');
    var violatorHeight;

    // add margin to bottom of body for violator, as not to cover footer
    $(window).resize(function () {
        violatorHeight = violator.outerHeight();

        if (violator.is(':visible')) {
            body.css('margin-bottom', violatorHeight + 'px').addClass('margin-bottom-transition');
        }
    }); // end window resize

    $(window).resize();

    // Closing the Violator
    $('.violator_close').click(function (e) {
        e.preventDefault();
        document.cookie = 'violatorCancel=true; path=/';
        violator.slideUp(400, 'linear');
        body.css('margin-bottom', '0px');
    });

    $('.violator_close_link').click(function (e) {
        e.preventDefault();
        document.cookie = 'violatorCancel=true; path=/';
        violator.slideUp(400, 'linear');
        body.css('margin-bottom', '0px');
    });
} // end if violator

// Hide and show the contact form
$('#contactFormShow').click(function (e) {
    e.preventDefault();
    var _this = $(this);

    _this.siblings('.contactUsContainer').slideDown();
    _this.slideUp();
});

$('#contactFormHide').click(function (e) {
    e.preventDefault();
    var _this = $(this);

    _this.closest('.contactUsContainer').slideUp();
    $('#contactFormShow').slideDown();
});

SetupQuantitySteppers('');

// Toggle Overlay on click
$('.overlay-container .overlay-container-label').click(function () {
    $(this).closest('.overlay-container')
        .toggleClass('showOverlay');
});

$('.overlay-container .btn_overlay-cancel').click(function () {
    $(this).closest('.overlay-container')
        .toggleClass('showOverlay');
});


// Toggle Icon Overlay on hover
$('.icn.overlay-container').hover(function () {
    $(this).addClass('showOverlay');
}, function () {
    $(this).removeClass('showOverlay');
});

function TogglePrice(productID, InstanceID) {
    // Since some coupons don't have strike through price do this check to make sure at least one isHidden.
    if ($('#regular-price-' + productID + '-' + InstanceID).hasClass('isHidden') || $('#sale-price-' + productID + '-' + InstanceID).hasClass('isHidden')) {
        $('#regular-price-' + productID + '-' + InstanceID).toggleClass('isHidden');
        $('#sale-price-' + productID + '-' + InstanceID).toggleClass('isHidden');
    }
}

var bAddToAutoReorderInProgress = false;
function HandleAutoReorderOverlaySet(buttonObject, iOPrd, szInstanceId) {
    if (bAddToAutoReorderInProgress) {
        return;     // Already a call, so leave
    }
    bAddToAutoReorderInProgress = true;

    var iFrequency = getAutoReorderFrequency(iOPrd, szInstanceId);
    if (iFrequency !== 0) {
        

        // Convert the JavaScript object into a jQuery object (do once for performance)
        var jqButtonObject = $(buttonObject);

        // Ajax CSRF token might not be present if disabled
        var AjaxTokenForUrl = '';
        if (typeof Ajax_CSRF_Token !== 'undefined') {
            AjaxTokenForUrl = '&ajaxToken=' + Ajax_CSRF_Token;
        }

        var addPath = '/handlers/addautoreorder.ashx?productId=' + iOPrd + AjaxTokenForUrl + '&freq=' + iFrequency;

        $.ajax({
            type: 'GET',
            url: addPath,
            cache: false,
            dataType: 'html',
            complete: function (data, status) {
                // This fires after success
                bAddToAutoReorderInProgress = false;       // Set to show done with the call

                //Need to show 'Manage not Set'
            },
            success: function (data) {
                jqButtonObject.removeClass('isLoading');
                //var oSetButton = $('#setAutoReorder');
                //oSetButton.empty();
                //oSetButton.html('<a href="/secure/autoreorder/manage" class="btn btn_plain"><i class="icn icn_autoreorder" aria-hidden="true"></i>Manage Item on Auto-Reorder</a>');
                //oSetButton.find('.btn').focus();
                window.location.href = window.location.href;
            },
            error: function () {
                //jqButtonObject.removeClass('isLoading');
                //jqButtonObject.removeClass('isDisabled');
                //jqButtonObject.removeAttr('disabled', 'disabled');
            },
        });
    }
}

function ShowAddToCartDefaultButton(szInstanceId) {
    $('#atcb_btn_' + szInstanceId).show();
    $('#atarb_btn_' + szInstanceId).hide();
    $('#atcb_arw_global_' + szInstanceId).hide();
    $('#atcb_btn_area_' + szInstanceId).show();
    $('#atcb_setar_' + szInstanceId).removeClass('isDisabled');
    $('#atcb_qtyds_' + szInstanceId).removeClass('isDisabled');
}

function ScrollToElementId(szScrollToElement, szScrollToID) {
    var szScrollSelector = szScrollToElement + '[id="' + szScrollToID + '"]';
    var scrollAmount = $(szScrollSelector).offset().top - 100;
    $('html,body').animate({ scrollTop: scrollAmount }, 'slow');
}

function SetupQuantitySteppers(szParentScopeSelector) {
    var $btns = $(szParentScopeSelector + ' .qty-button');
    var $btnsParents = $btns.parent();
    // Unbind if already set for some reason
    $btns.unbind('click'); $btnsParents.each(function () {
        var $parent = $(this);
        var $children = $parent.find('.qty-button'); if ($parent.hasClass('isDisabled')) {
            $children.attr('disabled', 'disabled');
        }
    });    // QTY Button +/- and button update
    $btns.click(function () {
        var $this = $(this);
        var $thisParent = $this.parent();
        var $accessibleMsg = $this.siblings('.quantityAmount'); if (!$thisParent.hasClass('disabled')) {
            var $ctaLayout = $this.closest('.product-bd-main-cta-layout');
            var $input = $this.siblings('input');
            var $inputVal = $input.val();
            var $count = parseInt($input.val()); if ($this.hasClass('qty-button-minus') && $count > 1) {
                $inputVal = $count - 1;
                $input.val($inputVal);
            } else if ($this.hasClass('qty-button-plus') && $count < 10) {
                $inputVal = $count + 1;
                $input.val($inputVal);
            }
            $accessibleMsg.empty().text('Quantity Changed: ' + $inputVal);
            var $btnAddToCart = $ctaLayout.find('.btn_addtocart');
            HandleAddToCartAutoReorderButtons($btnAddToCart, false, $inputVal, false);
        }
    });
}

$(function () {
    ShowHideYouMayAlsoLike();

    EnterKeyButtonDefault();
});

function EnterKeyButtonDefault() {
    // Need to be specific enough with attribute value to find the correct specific button
    $('input[data-enter-key-button]').each(function (index) {
        var jqInput = $(this);
        var szButtonIdFragment = jqInput.attr('data-enter-key-button');
        var oButton = $('form').find('[id*=\'' + szButtonIdFragment + '\']');
        jqInput.attr('onkeypress', 'clickButtonOnEnter(\'' + oButton.attr('id') + '\', event);');
    });
}

function AdjustProductBlockAfterAjax(szParentScopeSelector) {
    SetupQuantitySteppers(szParentScopeSelector);
    window.app.destroyTooltips();
    window.app.createTooltips();
}

function SetAspNetProgressIndicator() {
    var jqClickedObject = $(event.currentTarget);

    // Do client side validation check (with group if present)
    var bPageValid = true;
    if (typeof Page_ClientValidate === 'function') {
        // ValidationGroup ASP.NET attribute doesn't get to client so had to duplicate in data- attribute
        var valGroup = jqClickedObject.attr('data-aspnet-validation-group');
        if (valGroup === undefined) {
            valGroup = '';
        }

        if (!Page_ClientValidate(valGroup)) {
            bPageValid = false;
        }
    }

    if (bPageValid) {
        var parentContainer = jqClickedObject.parent('.ajaxBtn,.ajaxBtn_full');
        if (parentContainer.length > 0) {
            parentContainer.addClass('isLoading isDisabled');
        }
    }
}

function HandleFilterTagRemove(oFilterTagButton) {
    var jqFilterTagButton = $(oFilterTagButton);
    var szFilter = '';
    var szFilterValue = '';

    if (typeof jqFilterTagButton.data('shs-filter-tag') !== 'undefined') {
        szFilter = 'data-shs-filter';
        szFilterValue = jqFilterTagButton.data('shs-filter-tag');
    }

    if ('' !== szFilter) {
        var oCheckbox = $('input[' + szFilter + '=' + '\'' + szFilterValue + '\']');
        oCheckbox.prop('checked', false);

        ManageFiltersAndSort(false, true);
    }
}

function SetCookie(name, value) {
    document.cookie = name + '=' + value + '; path=/';
}

function DeleteCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999; path=/';
}

function ExecuteAjaxScripts(ajaxReturnData) {
    var ajaxReturnDataJQ = $(ajaxReturnData);
    var ajaxScripts = ajaxReturnDataJQ.filter('script.ajaxScripts');
    $('#ajaxScriptToExecute').empty();
    ajaxScripts.each(function (i) {
        $('#ajaxScriptToExecute').append($(this));
    });
}

function IsBreakpoint_SM() {
    return $('html').hasClass('smViewport');
}

function IsBreakpoint_MD() {
    return $('html').hasClass('mdViewport');
}

function IsBreakpoint_LG() {
    return $('html').hasClass('lgViewport');
}


function clickButtonOnEnter(buttonToPress, event) {
    if (event.keyCode == 13) {
        var button = document.getElementById(buttonToPress);
        button.click();
        event.preventDefault();
        return false;
    }
}

function agilOneClientCartUpdated(cartProducts) {
    if (typeof $A1 !== 'undefined') {
        var agileOneClientCartEvent;
        if (typeof agilOneCustomer === 'undefined') {
            agileOneClientCartEvent = $A1.Event({ type: 'cartUpdated' });
        } else {
            agileOneClientCartEvent = $A1.Event({ type: 'cartUpdated', customer: agilOneCustomer });
        }

        var arrIds = cartProducts.split(',');
        for (i = 0; i < arrIds.length; i++) {
            var pId = arrIds[i];

            agileOneClientCartEvent.add('targets', $A1.Target({ SourceProductNumber: pId }));
        }

        agileOneClientCartEvent.send();
    }
}

function RFKClickProductEvent(iProductNumber, szRFKid) {
    window.rfk = window.rfk || [];

    rfk.push(["trackEvent", {
        "type": "widget",
        "name": "click",
        "value": {
            "rfkid": szRFKid,
            "f": "sp",
            "index": 0,
            "products": [{
                "sku": iProductNumber
            }]
        }
    }]);
}

function addItemToCartFromCatalog(productId) {

    // Ajax CSRF token might not be present if disabled
    var AjaxTokenForUrl = '';
    if (typeof Ajax_CSRF_Token !== 'undefined') {
        AjaxTokenForUrl = '&ajaxToken=' + Ajax_CSRF_Token;
    }
    const pQuantity = 1;
    var addPath = '/addtocart.ashx?productId=' + productId + '&quantity=' + pQuantity + AjaxTokenForUrl;

    $.ajax({
        type: 'GET',
        url: addPath,
        cache: false,
        dataType: 'html',
        complete: function (data, status) {
            // This fires after success
        },
        success: function (data) {
            retData = eval('(' + trimString(data) + ')');
            if (retData.itemAdded != null) {
                UpdateCartContents(retData.numberItems, retData.htmlHeaderCart);

                if (Modernizr.sessionstorage) {
                    sessionStorage.setItem('cartQty', retData.numberItems);
                    sessionStorage.setItem('cartContents', retData.htmlHeaderCart);
                }

                recordAnalyticsForAdd(retData.itemAdded, retData.firstItem, retData.price, retData.eVar62);

                recordReflektionForAdd(retData.itemAdded);

                recordPinterestForAdd(retData.itemAdded, retData.price, pQuantity);

                agilOneClientCartUpdated(retData.cartProducts);
            }
        },
        error: function () {
            alert('error');
        },
    });
}

function HideAddToCartAlertMessage(instanceID) {
    $('#pb_alert_' + instanceID).addClass("isHidden");
}


function RefreshThisPage() {
    window.location.reload();
}


var productIdToDelete;
function CustomizeRemoveCartModal(event, pId, productName) {
    $("#RemoveCartProductName").text(productName);
    event.stopPropagation();
    event.preventDefault();
    productIdToDelete = pId;
}


function DeleteItemFromCart() {
    SetAspNetProgressIndicator();
    window.location.href = "/checkout/cart?omn=remove&rprd=" + productIdToDelete;
    return false;
}


function FireImgPixel(szDataAttribute) {    
    var oImg = $('img[' + szDataAttribute + ']');
    if (oImg.length > 0) {
        oImg.attr('src', oImg.attr(szDataAttribute));
    } 
}

function CallLiveIntentPush(keyValuePairsToSend) {
    window.liQ = window.liQ || [];
    window.liQ.push(keyValuePairsToSend);
}